<template>
  <div class="list-item">
    <div class="checked-line" v-if="item.marked"></div>
    <div class="mark" @click="onMarked">
      <div class="check">
        <img
          v-if="item.marked"
          src="../../../assets/images/icons/check.svg"
          alt=""
          class="checked"
        />
        <img v-else src="../../../assets/images/icons/ellipse.svg" alt="" />
      </div>
    </div>
    <span :class="['amount', item.marked ? 'text-muted' : '']">{{
      item.amount
    }}</span>
    <span :class="['unit', item.marked ? 'text-muted' : '']">{{
      item.unit
    }}</span>
    <span :class="['title', item.marked ? 'text-muted' : '']">{{
      item.title
    }}</span>

    <div class="delete" @click="onDelete">
      <img src="../../../assets/images/icons/trash.svg" alt="" />
    </div>
    <confirmation-dialogue ref="confirmDialogue"></confirmation-dialogue>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      showConfirmation: false,
    };
  },
  inject: ["activePage"],
  components: {},
  props: ["item"],
  methods: {
    ...mapActions(["toggleShoppingListItemMark", "removeItemFromShoppingList"]),
    async onDelete() {
      this.removeItemFromShoppingList(this.item.id);
    },
    onMarked() {
      this.toggleShoppingListItemMark(this.item.id);
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeShoppingListPage;
    //console.log(this.item);
  },
};
</script>

<style scoped>
.list-item {
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  border-radius: 5px;
  flex-wrap: nowrap;
  display: flex;
  position: relative;
}

.amount {
  min-width: 40px;
  width: 10%;
  text-align: start;
  vertical-align: center;
}

.title {
  width: 40%;
  vertical-align: center;
}

.unit {
  min-width: 80px;
  width: 10%;
  text-align: start;
  vertical-align: center;
}

.mark {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.mark .check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mark .check img {
  height: 24px;
}

/* .mark .check img.checked {
  width: 26px;
} */

.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.delete img {
  width: 16px;
  height: 16px;
}

.checked-line {
  width: calc(100% - 80px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: solid 1px;
  border-color: var(--main-color-red);
}
</style>