<template>
  <div class="">
    <confirmation-dialogue ref="confirmDialogue"></confirmation-dialogue>
    <top-nav-bar></top-nav-bar>
    <div class="page position-absolute" id="shoppinglist">
      <div class="">
        <section class="container mb-2">
          <div class="row justify-content-center">
            <h1 class="col-8 text-center text-handwriting">
              Meine Einkaufsliste
            </h1>
            <p class="text-muted text-center col-12" @click="onDelete">
              <img src="../../assets/images/icons/trash.svg" class="trash" />
              Ganze Einkaufsliste leeren
            </p>
          </div>
        </section>
        <section class="container">
          <div>
            <transition-group name="list">
              <shopping-list-item
                v-for="item in getShoppingList"
                :key="item.id"
                :item="item"
              ></shopping-list-item>
            </transition-group>
          </div>
        </section>
        <section class="container">
          <form>
            <div class="form-wrapper">
              <div class="add" @mousedown="addItemToList">
                <plus-box-icon fillColor="#ffb51d" :size="36"></plus-box-icon>
              </div>
              <input
                type="text"
                class="form-control amount input-wrapper"
                placeholder="1"
                v-model="amount"
                @focus="onFocus"
                @blur="onFocusOut"
              />
              <input
                type="text"
                class="form-control unit input-wrapper"
                placeholder="gr"
                v-model="unit"
                @focus="onFocus"
                @blur="onFocusOut"
              />
              <input
                type="text"
                class="form-control title input-wrapper"
                placeholder="Zutat"
                v-model="title"
                @keyup.enter="addItemToList"
                @focus="onFocus"
                @blur="onFocusOut"
              />
            </div>
          </form>
        </section>
        <div
          id="soft-keyboard"
          ref="softkeyboard"
          v-show="keyboardIsVisible"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShoppingListItem from "./components/shoppingListItem.vue";
import PlusBoxIcon from "vue-material-design-icons/PlusBox.vue";
export default {
  inject: ["activePage"],
  components: { ShoppingListItem, PlusBoxIcon },
  data() {
    return {
      amount: null,
      unit: "",
      title: "",
      keyboardIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(["getShoppingList"]),
  },
  methods: {
    ...mapActions(["resetShoppingList", "addItemToShoppingList"]),
    async onDelete() {
      const ok = await this.$refs.confirmDialogue.show({
        message: `Willst Du die Einkaufsliste löschen?`,
        title: "Einkaufsliste löschen",
        okButton: "Löschen",
      });

      if (ok) {
        this.resetShoppingList();
      }
    },
    addItemToList() {
      console.log(this.amount, this.title, this.unit);
      if (this.amount || this.title || this.unit) {
        const item = {
          id: Date.now(),
          amount: this.amount,
          title: this.title,
          unit: this.unit,
        };
        this.addItemToShoppingList(item);
        this.resetInput();
      }
    },
    resetInput() {
      this.unit = "";
      this.amount = null;
      this.title = "";
    },
    onFocus() {
      this.keyboardIsVisible = true;
      setTimeout(() => {
        document
          .getElementById("soft-keyboard")
          .scrollIntoView({ behavior: "smooth" });
      }, 200);
    },
    onFocusOut() {
      this.keyboardIsVisible = false;
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeShoppingListPage;
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.list-move {
  transition: transform 1s;
}

.button-delete {
  text-decoration: underline;
}

.input-wrapper {
  border-bottom: solid 1px var(--main-color-yellow);
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.amount {
  min-width: 60px;
  width: 10%;
  text-align: center;
  vertical-align: center;
}

.title {
  width: 40%;
  vertical-align: center;
}

.unit {
  min-width: 80px;
  width: 10%;
  text-align: center;
  vertical-align: center;
}

.form-wrapper {
  margin: 6px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.add {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trash {
  width: 16px;
  height: 16px;
}

#soft-keyboard {
  height: 50vh;
}
</style>